import React, { useLayoutEffect } from 'react'
//import ScrollToTop from './ScrollToTop'
//import pages
import Home from '../pages/Home'
import About from '../pages/About'
import Signup from '../pages/Signup'
import Login from '../pages/Login'
import Giftshop2 from '../pages/Giftshop2'
import Detail from '../pages/Detail'
import { BookTicket } from '../pages/BookTicket'
import OneTimeVisitBookTicket from '../pages/OneTimeVisitBookTicket'
import AnualVisitBookTicket from '../pages/AnualVisitBookTicket'
import OneTimePassChecOut from '../pages/OneTimePassChecOut'
import Gallery from '../pages/Gallery'
import ForgotPassword from '../pages/ForgotPassword'
import ChangePassword from '../pages/ChangePassword'
import GalleryImageDetail from '../pages/GalleryImageDetail'
import Exhibition from '../pages/Exhibition'
import DetailExhibition from '../pages/DetailExhibition'
import AllExhibition from '../pages/AllExhibition'
import AllExhibitionDetailPage from '../pages/AllExhibitionDetailPage'
import LearnHistory from '../pages/LearnHistory'
import GiftShopPayout from '../pages/GiftShopPayout'
import Donate from '../pages/Donate'
import AttendanceLog from '../pages/AttendanceLog'
import HistoryDetail from '../pages/HistoryDetail'
import Admin from '../admin/AdminHomePage'
import AdminShopPage from '../admin/AdminShopPage'
import AdminShopProductDetail from '../admin/AdminShopProductDetail'
import AdminAddProducttoGiftShop from '../admin/AdminAddProducttoGiftShop'
import AdminUpdateGiftshopProduct from '../admin/AdminUpdateGiftshopProduct'
import AdminExhibitionPage from '../admin/AdminExhibitionPage'
import AdminExhDetailPage from '../admin/AdminExhDetailPage'
import AddNewExhibition from '../admin/AddNewExhibition'
import AdminGalleryPage from '../admin/AdminGalleryPage'
import AdminGalleryDetail from '../admin/AdminGalleryDetail'
import AddToGallery from '../admin/AddToGallery'
import AdminHistory from '../admin/AdminHistory'
import AdminHistoryDetailPage from '../admin/AdminHistoryDetailPage'
import AddHistory from '../admin/AddHistory'
import AdminSettings from '../admin/AdminSettings'
import UpdateExhibition from '../admin/UpdateExhibition'
import AdminGalleryUpdatePage from '../admin/AdminGalleryUpdatePage'
import AdminHistoryUpdate from '../admin/AdminHistoryUpdate'

//import routes, route & uselocation
import { Routes, Route, useLocation, Navigate } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
//import { wait } from '@testing-library/user-event/dist/utils'

// Add Protected Route Component
const ProtectedAdminRoute = ({ children }) => {
    const isAdmin = localStorage.getItem('isAdmin') === 'true'
    const isAuthenticated = localStorage.getItem('token')

    if (!isAuthenticated || !isAdmin) {
        return <Navigate to="/login" replace />
    }

    return children
}

const NavRouter = () => {

    const location = useLocation();
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname])
    return (
        <AnimatePresence initial={true} >
            <Routes key={location.pathname} location={location}>
                <Route path='/' element={<Home />} />
                <Route path='/about' element={<About />} />
                <Route path='/signup' element={<Signup />} />
                <Route path='/forgotpassword' element={<ForgotPassword />} />
                <Route path='/changePassword' element={<ChangePassword />} />
                <Route path='/login' element={<Login />} />
                <Route path='/giftshop' element={<Giftshop2 />} />
                <Route path='/giftshop/:id' element={<Detail />} />
                <Route path='/bookticket' element={<BookTicket />} />
                <Route path='/onetimevisit' element={<OneTimeVisitBookTicket />} />
                <Route path='/anualvisit' element={<AnualVisitBookTicket />} />
                <Route path='/onetimepasscheckout' element={<OneTimePassChecOut />} />
                <Route path='/gallery' element={<Gallery />} />
                <Route path='/gallery/:id' element={<GalleryImageDetail />} />
                <Route path='/exhibition' element={<Exhibition />} />
                <Route path='/exhibition/:id' element={<DetailExhibition />} />
                <Route path='/allexhibition' element={<AllExhibition />} />
                <Route path='/allexhibition/:id' element={<AllExhibitionDetailPage />} />
                <Route path='/history' element={<LearnHistory />} />
                <Route path='/giftshoppayout' element={<GiftShopPayout />} />
                <Route path='/donate' element={<Donate />} />
                <Route path='/attendant' element={<AttendanceLog />} />
                <Route path='/history/:id' element={<HistoryDetail />} />

                <Route path='/admin' element={
                    <ProtectedAdminRoute>
                        <Admin />
                    </ProtectedAdminRoute>

                } />

                <Route path='/adminshop' element={
                    <ProtectedAdminRoute>
                        <AdminShopPage />
                    </ProtectedAdminRoute>
                } />
                <Route path='/AdminShopProductDetail/:id' element={
                    <ProtectedAdminRoute>
                        <AdminShopProductDetail />
                    </ProtectedAdminRoute>
                } />
                <Route path='/AdminAddProducttoGiftShop' element={
                    <ProtectedAdminRoute>
                        <AdminAddProducttoGiftShop />
                    </ProtectedAdminRoute>

                } />
                <Route path='/adminExhibition' element={
                    <ProtectedAdminRoute>
                        <AdminExhibitionPage />
                    </ProtectedAdminRoute>
                } />
                <Route path='/adminExhDetail/:id' element={
                    <ProtectedAdminRoute>
                        <AdminExhDetailPage />
                    </ProtectedAdminRoute>

                } />
                <Route path='/addexhibition' element={
                    <ProtectedAdminRoute>
                        <AddNewExhibition />
                    </ProtectedAdminRoute>
                } />
                <Route path='/admingallery' element={
                    <ProtectedAdminRoute>
                        <AdminGalleryPage />
                    </ProtectedAdminRoute>
                } />
                <Route path='/gallerydetail/:id' element={
                    <ProtectedAdminRoute>
                        <AdminGalleryDetail />
                    </ProtectedAdminRoute>
                } />
                <Route path='/addtogallery' element={
                    <ProtectedAdminRoute>
                        <AddToGallery />
                    </ProtectedAdminRoute>
                } />
                <Route path='/adminhistory' element={
                    <ProtectedAdminRoute>
                        <AdminHistory />
                    </ProtectedAdminRoute>

                } />
                <Route path='/adminhistorydetail/:id' element={
                    <ProtectedAdminRoute>
                        <AdminHistoryDetailPage />
                    </ProtectedAdminRoute>
                } />
                <Route path='/addhistory' element={
                    <ProtectedAdminRoute>
                        <AddHistory />
                    </ProtectedAdminRoute>

                } />
                <Route path='/adminsettings' element={
                    <ProtectedAdminRoute>
                        <AdminSettings />
                    </ProtectedAdminRoute>
                } />
                <Route path='/admineditproduct/:id' element={
                    <ProtectedAdminRoute>
                        <AdminUpdateGiftshopProduct />
                    </ProtectedAdminRoute>
                } />
                <Route path='/updateexhibition/:id' element={
                    <ProtectedAdminRoute>
                        <UpdateExhibition />
                    </ProtectedAdminRoute>
                } />
                <Route path='/updateartwork/:id' element={
                    <ProtectedAdminRoute>
                        <AdminGalleryUpdatePage />
                    </ProtectedAdminRoute>
                } />
                <Route path='/updatehistory/:id' element={
                    <ProtectedAdminRoute>
                        <AdminHistoryUpdate />
                    </ProtectedAdminRoute>
                } />
            </Routes>
        </AnimatePresence>
    )
}

export default NavRouter