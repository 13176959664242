import React, { useEffect, useRef, useState } from 'react';
import Nav from './Nav'
import Arrow from '../assets/admin/Arrow 1.png'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
import Popup from '../components/Notification';

export default function AdminAddProducttoGiftShop() {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        name: '',
        categories: [],
        amount: '',
        featuredImage: '',
        images: [],
        stock: 0,
        additionalInfo: '',
        description: '',
        instock: true
    });

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const modalRef = useRef(null);

    useEffect(() => {
        let timer;
        if (showErrorModal) {
            timer = setTimeout(() => {
                setShowErrorModal(false);
            }, 10000);
        }

        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setShowErrorModal(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            clearTimeout(timer);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showErrorModal]);
    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setIsLoading(true);

        const reqObj = {
            name: formData.name,
            categories: formData.categories,
            amount: formData.amount,
            featuredImage: formData.featuredImage,
            images: formData.images,
            stock: formData.stock,
            additionalInfo: formData.additionalInfo,
            description: formData.description,
        }
        console.log(reqObj)

        try {
            const response = await axios.post(
                'https://isinrimuseum-backend.fly.dev/api/v1/products',
                {
                    name: formData.name,
                    categories: formData.categories,
                    amount: formData.amount,
                    featuredImage: formData.featuredImage,
                    images: formData.images,
                    stock: formData.stock,
                    additionalInfo: formData.additionalInfo,
                    description: formData.description,
                }
            );
console.log(response)
            if (response.status === 201) {
                setSuccessMessage('Product created successfully, redirecting...');
                setShowSuccessModal(true);
                setFormData({
                    name: '',
                    categories: '',
                    amount: '',
                    featuredImage: '',
                    images: '',
                    stock: 0,
                    additionalInfo: '',
                    description: ''
                });
                navigate('/adminshop')
            }


           
            // setTimeout(() => {
            //     if (formData.loginType === 'Admin') {
            //         localStorage.setItem('isAdmin', 'true')
            //         navigate('/admin');
            //     } else if (formData.loginType === 'Guest') {
            //         navigate('/');
            //     } else if (formData.loginType === 'Exhibitor') {
            //         navigate('/exhibition');
            //     }
            // }, 2000);
        } catch (err) {
            setError(err.response ? err.response.data.details : err.message);
            setShowErrorModal(true);
            console.error('Login error:', err);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            {(showErrorModal || showSuccessModal) && (
                <div className='fixed top-4 right-1/4 z-50' ref={modalRef}>
                    <Popup
                        type={showSuccessModal ? 'success' : 'error'}
                        message={showSuccessModal ? successMessage : error}
                        onClose={() => {
                            setShowErrorModal(false);
                            setShowSuccessModal(false);
                        }}
                    />
                </div>
            )}

            {isLoading && (
                <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50'>
                    <div className='animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-white'></div>
                </div>
            )}
            <div className='lg:flex'>
                <div>
                    <Nav />
                </div>

                <div className='w-full pb-14 pt-3 bg-[#EFF2F2]'>
                    <div className='p-4 px-14 bg-white w-full hidden lg:flex text-justify justify-between'>
                        <div><p className='font-medium'>Giftshop</p></div>
                        <div><p className='font-medium'>Hello Admin</p></div>
                    </div>
                    <div className='m-6'>
                        <Link to={'/adminshop'} >
                            <img src={Arrow} alt='arrow direction' />
                        </Link>
                        <div className='mt-6'>
                            <p className='text-[#424038] font-medium'>Gift Information</p>
                        </div>
                    </div>

                    <form className='m-6 ' onSubmit={handleSubmit}>

                        <label className='text-[#424038] ' >
                            <p>Title</p>
                            <input
                                required
                                onChange={handleInputChange}
                                type='text'
                                value={formData.name}
                                name='name'
                                placeholder='Enter here'
                                className='border border-[#424038] outline-[#424038] bg-[#EFF2F2] placeholder:text-[#424038] placeholder:text-[12px] placeholder:pl-4 rounded w-full'
                            />
                        </label>
                        <label className='text-[#424038] my-2' >
                            <p className='pt-6'>Category</p>
                            <input
                                onChange={handleInputChange}
                                type='text'
                                value={formData.categories}
                                name='categories'
                                placeholder='Enter Category'
                                className='border border-[#424038] outline-[#424038] bg-[#EFF2F2] placeholder:text-[#424038] placeholder:text-[12px] placeholder:pl-4 rounded w-full'
                            />
                        </label>


                        <div className='grid grid-cols-2 gap-4 mt-6'>
                            <div>
                                <label className='text-[#424038] ' >
                                    <p>Amount</p>
                                    <input
                                        onChange={handleInputChange}
                                        type='text'
                                        value={formData.amount}
                                        name='amount'
                                        placeholder='Enter Amount here'
                                        className='border border-[#424038] outline-[#424038] bg-[#EFF2F2] placeholder:text-[#424038] placeholder:text-[12px] placeholder:pl-4 rounded w-full'
                                    />
                                </label>
                            </div>
                            <div>
                                <label className='text-[#424038] ' >
                                    <p>Quantity</p>
                                    <input
                                        onChange={handleInputChange}
                                        type='number'
                                        value={formData.stock}
                                        name='stock'
                                        placeholder='Enter here'
                                        className='border border-[#424038] outline-[#424038] bg-[#EFF2F2] placeholder:text-[#424038] placeholder:text-[12px] placeholder:pl-4 rounded w-full'
                                    />
                                </label>
                            </div>
                        </div>


                        <div className='mt-6 mb-1 text-[#424038] '>
                            <p>Featured Image</p>
                        </div>
                        <div className='mb-6 '>
                            {/* div for main product image */}
                            <div className='mb-10'>
                                <input type='file'
                                    onChange={handleInputChange}
                                    value={formData.featuredImage}
                                    name='featuredImage'
                                />

                            </div>

                            {/* for other images */}
                            <div className='mb-1 text-[#424038] '>
                                <p>Other Images</p>
                            </div>
                            <div className='lg:flex'>
                                <div className=' '>
                                    <div className=' '>
                                        <input type='file'
                                            value={formData.images}
                                            onChange={handleInputChange}
                                            name='images'
                                        />
                                    </div>
                                    <div className=''>
                                        <input type='file'
                                            onChange={handleInputChange}
                                            value={formData.images}
                                            name='images'
                                        />
                                    </div>
                                </div>
                                <div className=' '>
                                    <div className=' '>
                                        <input type='file'
                                            onChange={handleInputChange}
                                            value={formData.images}
                                            name='images'
                                        />
                                    </div>
                                    <div className=''>
                                        <input type='file'
                                            onChange={handleInputChange}
                                            value={formData.images}
                                            name='images'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <label className='text-[#424038] ' >Description</label><br />
                        <textarea
                            onChange={handleInputChange}
                            value={formData.description}
                            name='description'
                            className='h-36 mb-6 w-full border border-[#424038] outline-[#424038] bg-[#EFF2F2] placeholder:text-[#424038] placeholder:text-[12px] placeholder:pl-4 rounded'>
                        </textarea>

                        <label className='text-[#424038] '>Additional Information</label><br />
                        <textarea
                            onChange={handleInputChange}
                            value={formData.additionalInfo}
                            name='additionalInfo'
                            className='h-24 w-full border border-[#424038] outline-[#424038] bg-[#EFF2F2] placeholder:text-[#424038] placeholder:text-[12px] placeholder:pl-4 rounded'>

                        </textarea>
                        <label >
                            Instock
                            <input
                                onChange={handleInputChange}
                                type='checkbox'
                                name='instock'
                                checked={formData.instock}
                                placeholder='Enter here'
                                className=''
                            />
                        </label>
                        <h1 className='mt-8 text-[#424038] font-bold text-1xl'>Shipping Information</h1>
                        {/* <label className='text-[#424038] my-2' >
                            <p className='pt-6'>Delivery Option</p>
                            <input
                                onChange={handleInputChange}
                                type='text'
                                name='title'
                                placeholder='Enter here'
                                className='border border-[#424038] outline-[#424038] bg-[#EFF2F2] placeholder:text-[#424038] placeholder:text-[12px] placeholder:pl-4 rounded w-full'
                            />
                        </label> */}
                        <button className='bg-[#424038] text-white w-full mb-36 mt-12 p-2'>Post</button>
                    </form>
                </div>
            </div>
        </div>
    )
}
